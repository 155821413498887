body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
  color: #775c29;
  width: 100%;
  height: 100%;
  background-color: #f4ede1;
}

.App-logo {
  pointer-events: none;
  text-align: center;
}

.App-container{
    padding-bottom: 20px !important;
  padding-top: 20px !important;
}

.App-container {
  padding: 50px 0;
  background-color: #f4ede1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: calc(10px + 2vmin);
}

@keyframes footer-bus-animate {
  from {
    background-position: -20% 0;
  }
  to {
    background-position: 120% 0;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
}

.footer-cloud {
  animation: fadeIn 2s infinite alternate;
}

.footer-cloud.delay {
  animation-delay: 500ms;
}

.footer-bus {
  background-size: auto 40px;
  background-repeat: no-repeat;
  animation: footer-bus-animate 20000ms linear infinite;
}